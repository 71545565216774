import React from 'react'

import style from './HeadersParameters.module.css'

const HeadersParameters = ({ className, list = [] }) => {
  const classNames = `${style.root} ${className ? className : ''}`;

  return (
    <div className={classNames}>
      <h2>Headers</h2>

      <table className={style.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {
            list.map(item => (
              <tr>
                <td>{item.name}</td>
                <td>{item.desc}</td>
                <td>{item.type}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default HeadersParameters
