import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import style from './RequestResponse.module.css'

const RequestResponse = ({ className }) => {
  const classNames = `${style.root} ${className ? className : ''}`;

  return (
    <div className={classNames}>
      <h2>JSON get data example</h2>
      <p>This is an example of how to get discount codes</p>

      <SyntaxHighlighter language="javascript" style={dark}>
        {`
          {
            "code": String
          }
        `}
      </SyntaxHighlighter>
    </div>
  )
}

export default RequestResponse
