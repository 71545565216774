import React from "react";
import Layout from "../layouts/en-minified";
import RequestType from "../components/styleguide/RequestType";
import RequestParameters from "../components/styleguide/RequestParameters";
import RequestDynamicCodeResponse from "../components/styleguide/RequestDynamicCodeResponse";
import HeadersParameters from "../components/styleguide/HeadersParameters";


const Api = props => {
    return (
        <Layout location={props.location}>

          <div className="ApiPage">
            <h1>Discount codes</h1>

            <p>Retrieve codes for offer when it has the property hasMultipleCodes=true</p>

            <RequestType path='https://tyviso.com/getDiscountCode?id={offerID}' />
            
            <HeadersParameters
              list={[
                {
                  name: 'token',
                  desc: 'Tyviso will provide your unique token',
                  type: 'String'
                }
              ]}
            />

            <RequestParameters
              hidePartnerId
              list={[
                {
                  name: 'id',
                  desc: 'Offer ID',
                  type: 'String'
                }
              ]}
            />

            <RequestDynamicCodeResponse />

          </div>
          
        </Layout>
    );
};

export default Api;